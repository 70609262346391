import React from 'react';
import '../styles/PRRow.css'

const PRRow = ({ lift }) => {
  return (
    <tr>
      <td>{lift.lift}</td>
      <td>{lift.date}</td>
      <td>{lift.weight > 0 ? lift.weight: '-'}</td>
      <td>{lift.reps}</td>
      {/* reps om bara chins, formel om >1 rep annars vikt */}
      <td>{lift.lift == 'chins' ? lift.reps : Math.round(lift.reps > 1 ? lift.weight * (1+lift.reps/30) : lift.weight)}</td>
    </tr>
  );
};

export default PRRow;
