// src/App.js

import React, { useState, useEffect } from 'react';
import PRGrid from './components/PRGrid';
import base_url from './config';
import './App.css';


function App() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    fetch(`${base_url}/api/users`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Assuming the backend returns JSON data
    })
    .then((data) => {
      setUsers(data.users); // Update state with the data
    })
    .catch((err) => {
      setError(err.message); // Handle errors
    });
}, []);

  return (
    <div className="App">
      <header>
        <h1>Sjukt stenhårt gym</h1>
      </header>
      <PRGrid users={users} />
    </div>
  );
}

export default App;
