import '../styles/PRTable.css';
import PRRow from './PRRow';

const PRTable = ({ users, lifts }) => {
  return (
    <div className="pr-table">
      <h2>{users.name}</h2>
      <table>
        <thead>
          <tr>
            <th>Lift</th>
            <th>Date</th>
            <th>Weight (kg)</th>
            <th>Reps</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {lifts.length > 0 ? (
            lifts.map((lift, index) => (
              <PRRow key={index} lift={lift} />
            ))
          ) : (
            <tr>
              <td colSpan="4">No PRs available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PRTable;
