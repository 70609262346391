import React from 'react';
import { useEffect, useState } from "react";
import PRTable from './PRTable';
import '../styles/PRGrid.css';
import base_url from '../config';

const PRGrid = ({ users }) => {
  const [userPRs, setUserPRs] = useState({});

  useEffect(() => {
    const fetchPRs = async () => {
      try {
        const response = await fetch(`${base_url}/api/prs`);
        const data = await response.json();
  
        // Extract the prs array from the data
        const prsData = data.prs;
  
        // Ensure prsData is an array
        if (!Array.isArray(prsData)) {
          throw new Error("API did not return an array of PRs");
        }
  
        // Group PRs by user_id
        const prsByUser = prsData.reduce((acc, pr) => {
          if (!acc[pr.user_id]) acc[pr.user_id] = [];
          acc[pr.user_id].push(pr);
          return acc;
        }, {});
  
        setUserPRs(prsByUser);
      } catch (error) {
        console.error("Error fetching PRs:", error);
      }
    };
  
    fetchPRs();
  }, []);
  console.log("prs",userPRs);
  
  return (
    <div className="prgrid">
      {users.map((user) => (
        <div key={user.id} className="prgrid-item">
          <PRTable users={user} lifts={userPRs[user.id]}/>
        </div>
      ))}
    </div>
  );
};

export default PRGrid;
